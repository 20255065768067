import React, { useMemo } from 'react'
import NormalLayout from '../components/NormalLayout'
import { PageModelTitle } from '../model/PageModel'
import CommunityTab from '../components/communityTab'
import CommunityData from '../components/communityData'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl-v6'

export default Announcements

function Announcements() {
  let pageModel = new PageModelTitle('Announcements', 'community-html')
  return <div>{NormalLayout(Main(), pageModel)}</div>
}

function Main() {
  const intl = useIntl()
  const locale = intl.locale
  const pageSize = 10
  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx (
        filter: { slug: { glob: "announcements/*" } },
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            slug
            frontmatter {
              title
              date(formatString: "YYYY-MM-DD")
              language
            }
          }
        }
      }
    }
  `)
  
  const [filteredData, paginationData] = useMemo(() => {
    const filtered = (allMdx?.edges || []).filter(
      (edge: any) => edge.node.frontmatter?.language === locale
    )
    
    const total = Math.ceil(filtered.length / pageSize)
    const pages = Array.from({ length: total }, (_, i) =>
      filtered.slice(i * pageSize, (i + 1) * pageSize)
    )
    
    return [filtered, pages]
  }, [allMdx?.edges, locale])

  return (
    <>
      <CommunityTab />
      <CommunityData filteredData={ filteredData } paginationData={ paginationData } locale={ locale } />
    </>
  )
}
